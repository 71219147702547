import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  GetChainStatement,
  GetChainStatements,
  GetPaymentStatementDetail,
  GetPharmacyPaymentStatements,
  Maybe,
  PatientAssistancePharmacyPaymentStatementEarlyPaymentStatusEnum,
  PatientAssistancePharmacyPaymentStatementStatusEnum,
  RequestInstantPayment,
  ResetPatientAssistancePharmacyPaymentStatementTransmittedDate,
  UpdatePaymentStatement,
} from '@generated/graphql';
import { PHARMACY_PAYMENT_STATEMENT_AGGREGATED_STATUS_LABEL_MAPPER } from '@libs/shared/domain/patient-assistance/pharmacy/payment-statement/shared-patient-assistance-pharmacy-payment-statement-aggregated-status-label-mapper';
import {
  AGGREGATED_STATUS_PENDING,
  AGGREGATED_STATUS_PENDING_ADJUSTED,
  SharedPatientAssistancePharmacyPaymentStatementAggregatedStatusType,
} from '@libs/shared/domain/patient-assistance/pharmacy/payment-statement/shared-patient-assistance-pharmacy-payment-statement-aggregated-status.type';
import { GraphqlService } from '@services/graphql.service';
import { GraphqlConnection } from '@services/graphql/generic/generic';
import { GetChainStatementQuery } from '@services/graphql/pharmacy/chain/get-chain-statement.query';
import { GetChainStatementsQuery } from '@services/graphql/pharmacy/chain/get-chain-statements.query';
import { GetPaymentStatementDetailQuery } from '@services/graphql/pharmacy/get-pharmacy-statement.query';
import { GetPharmacyStatementsQuery } from '@services/graphql/pharmacy/get-pharmacy-statements.query';
import { RequestInstantPaymentMutation } from '@services/graphql/pharmacy/request-instant-payment.mutation';
import { ResetPatientAssistancePharmacyPaymentStatementTransmittedDateMutation } from '@services/graphql/pharmacy/reset-payment-statement-transmitted-date.mutation';
import { UpdatePaymentStatementMutation } from '@services/graphql/pharmacy/update-payment-statement.mutation';
import {
  extraPaidClaimsSearchCriteria,
  extraPaymentReportColumns,
  paidClaimsSearchCriteria,
  paymentReportColumns,
} from '@services/pharmacy-payment-statements.columns';
import { DateRange } from '@shared/components/chitin/molecules/daterange-picker/date-range';
import { SelectListItem } from '@shared/models/select-list-item';
import { MutateResult } from '@shared/providers/graphql-client';
import { chain } from 'lodash';
import moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PharmacyPaymentStatementsService extends GraphqlService {
  public getPeriodEndLabel(periodEnd: string): string {
    return this.dateService.moveDate(periodEnd.substring(0, 10), -1, 'day').toISOString(true);
  }

  public mapStatusToBadgeClass(status: PatientAssistancePharmacyPaymentStatementStatusEnum) {
    return {
      paid: 'bg-success',
      unpaid: 'bg-light text-black',
      closed: 'bg-dark text-white',
      pending: 'bg-info text-white',
    }[status];
  }

  public createAggregatedStatusDropdownItems(): SelectListItem[] {
    return chain(Object.entries(PHARMACY_PAYMENT_STATEMENT_AGGREGATED_STATUS_LABEL_MAPPER))
      .filter(([key]) => ![AGGREGATED_STATUS_PENDING, AGGREGATED_STATUS_PENDING_ADJUSTED].includes(key))
      .map(([key, value]) => ({ name: value, value: key }))
      .value();
  }

  public mapAggregatedStatusToBadgeClass(status?: SharedPatientAssistancePharmacyPaymentStatementAggregatedStatusType): string {
    return status
      ? {
          paid: 'bg-success',
          paidAdjusted: 'bg-success',
          unpaid: 'bg-light text-black',
          pending: 'bg-info text-white',
          unpaidAdjusted: 'bg-light text-black',
          closed: 'bg-dark text-white',
          closedAdjusted: 'bg-dark text-white',
          pendingAdjusted: 'bg-info text-white',
        }[status]
      : '';
  }

  public mapAggregatedStatusToStatusName(status?: SharedPatientAssistancePharmacyPaymentStatementAggregatedStatusType): string {
    return status ? PHARMACY_PAYMENT_STATEMENT_AGGREGATED_STATUS_LABEL_MAPPER[status] : '';
  }

  public mapRequestStatusToBadgeClass(status: PatientAssistancePharmacyPaymentStatementEarlyPaymentStatusEnum) {
    return {
      accepted: 'bg-success',
      rejected: 'bg-danger text-black',
      requested: 'bg-warning text-black',
      unrequested: 'bg-light text-black',
    }[status];
  }

  getChainPaymentStatements(variables: GetChainStatements.Variables): Observable<GraphqlConnection<GetChainStatements.Node>> {
    return this.graphqlClient.query<GetChainStatements.Query>(GetChainStatementsQuery, variables).pipe(
      map(result => {
        this.processGraphqlQueryResult(result);
        const connection = result.data?.pharmacyChain?.paymentStatements ?? this.getEmptyConnection(result);
        return connection;
      }),
    );
  }

  getChainForPaymentStatementDetail(variables: GetChainStatement.Variables): Observable<Maybe<GetChainStatement.PharmacyChain>> {
    return this.graphqlClient.query<GetChainStatement.Query>(GetChainStatementQuery, variables).pipe(
      map(result => {
        this.processGraphqlQueryResult(result);
        return result.data?.pharmacyChain ?? null;
      }),
    );
  }

  getPharmacyPaymentStatements(
    variables: GetPharmacyPaymentStatements.Variables,
  ): Observable<GraphqlConnection<GetPharmacyPaymentStatements.Node>> {
    return this.graphqlClient.query<GetPharmacyPaymentStatements.Query>(GetPharmacyStatementsQuery, variables).pipe(
      map(result => {
        this.processGraphqlQueryResult(result);
        const connection = result.data?.tenant?.pharmacy?.paymentStatements ?? this.getEmptyConnection(result);
        return connection;
      }),
    );
  }

  getPharmacyForPaymentStatementDetail(
    variables: GetPaymentStatementDetail.Variables,
  ): Observable<Maybe<GetPaymentStatementDetail.Pharmacy>> {
    return this.graphqlClient.query<GetPaymentStatementDetail.Query>(GetPaymentStatementDetailQuery, variables).pipe(
      map(result => {
        this.processGraphqlQueryResult(result);
        return result.data?.tenant?.pharmacy ?? null;
      }),
    );
  }

  exportPaymentStatements({
    npi,
    periodEnd,
    status,
    isForChain,
  }: {
    npi: string;
    periodEnd: DateRange;
    isForChain: boolean;
    status?: Maybe<PatientAssistancePharmacyPaymentStatementStatusEnum>;
  }) {
    const urlParams = new HttpParams({
      fromObject: {
        periodEndFrom: moment(periodEnd.from).format('YYYY-MM-DD') + 'T05:00:00Z',
        periodEndTo: moment(periodEnd.to).add(1, 'day').format('YYYY-MM-DD') + 'T05:00:00Z',
        status: status ?? '',
        isForChain,
      },
    });
    const url = `${this.url.PA_SERVICE}/pharmacy-by-npi/${npi}/payment-statements/export?${urlParams.toString()}`;
    return this.http.get<{ fileUrl: string }>(url);
  }

  updatePaymentStatement(variables: UpdatePaymentStatement.Variables): Observable<MutateResult<UpdatePaymentStatement.Mutation>> {
    this.sanitizeInputType(variables.data);
    return this.graphqlClient.mutate(UpdatePaymentStatementMutation, variables);
  }

  resetPatientAssistancePharmacyPaymentStatementTransmittedDate(
    variables: ResetPatientAssistancePharmacyPaymentStatementTransmittedDate.Variables,
  ): Observable<MutateResult<ResetPatientAssistancePharmacyPaymentStatementTransmittedDate.Mutation>> {
    this.sanitizeInputType(variables.data);
    return this.graphqlClient.mutate(ResetPatientAssistancePharmacyPaymentStatementTransmittedDateMutation, variables);
  }

  requestInstantPayment(
    variables: RequestInstantPayment.Variables,
  ): Observable<Maybe<RequestInstantPayment.RequestPharmacyPaymentStatementEarlyPayment>> {
    this.sanitizeInputType(variables);
    return this.graphqlClient.mutate<RequestInstantPayment.Mutation>(RequestInstantPaymentMutation, variables).pipe(
      map(result => {
        this.processGraphqlMutationResult(result);
        return result.data?.requestPharmacyPaymentStatementEarlyPayment ?? null;
      }),
    );
  }

  getExtraBenefitTotalForDateRange(createdFrom: Date, createdTo: Date, pharmacyNcpdp: string) {
    return this.http.post<{ extraBenefitAmount: { count: number; sum: number } }>(
      this.url.AGGREGATES_URL + this.url.AGGREGATES_SERVICE.paidClaimsStats,
      {
        filter: {
          createdFrom,
          createdTo,
          pharmacyNcpdp,
        },
      },
    );
  }

  getPharmacyPaymentStatementReport(
    pharmacyName: string,
    periodStart: string | undefined,
    periodEnd: string | undefined,
    ncpdp: string,
    type: 'claims' | 'extra' = 'claims',
  ) {
    const today = new Date().toISOString();
    const body: Record<string, unknown> = {
      extract_name: 'pharmacy_remittance_report',
      enable_report_filename_options: 1,
      output_filename: `${pharmacyName.replace(/\W+/g, '-')}_${(periodStart ?? today).substring(0, 10)}_${this.dateService
        .moveDate(periodEnd, -1, 'day')
        .toISOString(true)
        .substring(0, 10)}.xlsx`,
      current_date: (periodEnd ?? today).substring(0, 10),
      file_by_pharmacy: 0,
      pharmacy_search_criteria: {
        ncpdp: ncpdp,
      },
      tenants: ['ALM', 'GLD', 'INCY'],
      spreadsheet_data: {
        file_type: 'xlsx',
        sheets: {
          Payments: type === 'claims' ? paymentReportColumns : extraPaymentReportColumns,
        },
      },
      paid_claims_search_criteria_list: type === 'extra' ? extraPaidClaimsSearchCriteria : paidClaimsSearchCriteria,
    };
    if (periodStart) {
      body.start_date = periodStart.substring(0, 10);
    }
    return this.http.post<string[]>(`${this.url.DATA_DELIVERY_DOWNLOAD_URL.replace('{tenant}', 'GLD')}`, body);
  }

  calculateUpcomingPaymentFromLastStatement(periodStart: string | Date, periodEnd: string | Date) {
    const _periodStart = moment(periodStart);
    const _periodEnd = moment(periodEnd);

    const nextPeriodStart =
      _periodStart.date() > 1
        ? _periodStart.add(1, 'month').startOf('month').set('hours', 5)
        : _periodStart.set('date', 16).set('hours', 5);
    const nextPeriodEnd =
      _periodEnd.date() > 15 ? _periodEnd.add(1, 'month').set('date', 15).set('hours', 5) : _periodEnd.endOf('month').set('hours', 5);
    return {
      periodStart: nextPeriodStart.toDate(),
      periodEnd: nextPeriodEnd.toDate(),
      paymentDate: nextPeriodEnd.add(1, 'day').toDate(),
    };
  }

  calculateUpcomingPaymentFromPeriodEnd(periodEnd: string | Date) {
    const _periodEnd = moment(periodEnd);

    const nextPeriodEnd = moment(periodEnd);
    const nextPeriodStart =
      _periodEnd.date() > 1
        ? _periodEnd.subtract(1, 'month').startOf('month').set('hours', 5)
        : _periodEnd.subtract(1, 'month').set('date', 16).set('hours', 5);
    return {
      periodStart: nextPeriodStart.toDate(),
      periodEnd: nextPeriodEnd.toDate(),
      paymentDate: nextPeriodEnd.add(1, 'day').toDate(),
    };
  }
}
