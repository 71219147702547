import { Component, computed, input } from '@angular/core';
import { NgStyle } from '@angular/common';

@Component({
  selector: 'chitin-icon',
  templateUrl: './chitin-icon.component.html',
  styleUrls: ['./chitin-icon.component.scss'],
})
export class ChitinIconComponent {
  icon = input.required<string>();
  color = input<'primary' | 'danger' | 'success' | 'secondary' | 'text' | 'lighter-blue' | 'pink'>();
  clickable = input<boolean>();
  styles = input<NgStyle>();

  protected classes = computed<string>(() => {
    const colorClass = this.color() !== undefined ? `text-${this.color()}` : '';
    return `${this.icon()} ${colorClass}`;
  });
}
